import React, { Component } from 'react';
import connect from '../diagnostics/diagnostic.connect';
import CountUp from 'react-countup';
import { withTranslation } from '../../../i18n';
import ErrorBoundary from '../layout/widgets/errorboundary/errorboundary.component';
import Link from '../layout/widgets/activelink/activelink';

class HealthcarePlatform extends Component {
    state: any = {
        packageIndex: 0,
        showExtraIcon: false,
        screenWidth: null,
    }
    props: any;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            let width = window.innerWidth
            this.setState({ screenWidth: width })
        }
    }

    getHealthList() {
        let UIPackageList = [];
        let UIPackageItem =
            <div className="section1 footer-color nobottommargin mt-4 pt-5 pb-5">
                <div className="container pb-4">
                    <div className='row'>
                        <div key='vdc_c10' className='col-lg-12 col-md-12 col-sm-12 mb-4'>
                            <h4 className='header-underline vdc-font'>INDIA'S LARGEST HEALTHCARE PLATFORM</h4>
                        </div>
                    </div>
                    <div className='row'>
                        <div key='vdc_c11' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home p-3'>
                            <div className="counter"><CountUp end={200} duration={5} /> +
                                <span className='profession'>Professionals</span></div>
                            <div className="subtitle1">Radiologists, Pathologists &amp; Microbiologists
                            </div>
                        </div>
                        <div key='vdc_c12' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home p-3'>
                            <div className="counter"><CountUp end={50} duration={5} /> +
                                <span className='profession'>Million Customers and Counting</span></div>
                            <div className="subtitle1">Trusted by Every Age Group
                            </div>
                        </div>
                        <div key='vdc_c13' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home-last p-3'>
                            <div className="counter"><CountUp end={40} duration={5} />
                                <span className='profession'>Years of Experience</span></div>
                            <div className="subtitle1">in Delivering Quality Diagnostic Services
                            </div>
                        </div>
                        <div key='vdc_c14' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-right p-3'>
                            <div className="counter"><CountUp end={140} duration={5} /> +
                                <span className='profession'>Diagnostic Centres and Counting</span></div>
                            <div className="subtitle1">State-of-the-art Facilities with Best Customer Service
                            </div>
                        </div>
                        <div key='vdc_c15' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-right p-3'>
                            <div className="counter"><CountUp end={20} duration={5} />
                                <span className='profession'>Cities Across India</span></div>
                            <div className="subtitle1">India`s Largest Comprehensive Diagnostic Centre
                            </div>
                        </div>
                        <div key='vdc_c16' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 p-3 text-align'>
                            <div className="counter"><CountUp end={250} duration={5} /> +
                                <span className='profession'>Corporate Clients and Counting</span></div>
                            <div className="subtitle1">Most Preferred Diagnostic Partner
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        UIPackageList.push(UIPackageItem)
        if (UIPackageList.length > 0) {
            return (
                UIPackageList
            )
        }
    };
    render() {
        return (
            <ErrorBoundary>
                <div className="section1 footer-color nobottommargin mt-4 pt-5 pb-5">
                    <div className="container pb-4">
                        <div className='row'>
                            <div key='vdc_c1' className='col-lg-12 col-md-12 col-sm-12 mb-4'>
                                <h4 className='header-underline vdc-font'>INDIA'S LARGEST HEALTHCARE PLATFORM</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div key='vdc_c2' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home p-3'>
                                <div className="counter"><CountUp end={200} duration={5} /> +
                                    <span className='profession'>Professionals</span></div>
                                <div className="subtitle1">Radiologists, Pathologists &amp; Microbiologists
                                </div>
                            </div>
                            <div key='vdc_c3' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home p-3'>
                                <div className="counter"><CountUp end={50} duration={5} /> +
                                    <span className='profession'>Million Customers and Counting</span></div>
                                <div className="subtitle1">Trusted by Every Age Group
                                </div>
                            </div>
                            <div key='vdc_c4' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-home-last p-3'>
                                <div className="counter"><CountUp end={40} duration={5} />
                                    <span className='profession'>Years of Experience</span></div>
                                <div className="subtitle1">in Delivering Quality Diagnostic Services
                                </div>
                            </div>
                            <div key='vdc_c5' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-right p-3'>
                                <div className="counter"><CountUp end={140} duration={5} /> +
                                    <span className='profession'>Diagnostic Centres and Counting</span></div>
                                <div className="subtitle1">State-of-the-art Facilities with Best Customer Service
                                </div>
                            </div>
                            <div key='vdc_c6' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 borderit-right p-3'>
                                <div className="counter"><CountUp end={20} duration={5} />
                                    <span className='profession'>Cities Across India</span></div>
                                <div className="subtitle1">India`s Largest Comprehensive Diagnostic Centre
                                </div>
                            </div>
                            <div key='vdc_c7' className='col-lg-4 col-md-4 col-sm-4 col-xs-12 p-3 text-align'>
                                <div className="counter"><CountUp end={250} duration={5} /> +
                                    <span className='profession'>Corporate Clients and Counting</span></div>
                                <div className="subtitle1">Most Preferred Diagnostic Partner
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className="row text-center m-4">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 text-center">
                            <div className="card border-0 text-center" style={{ width: "15rem" }}>
                                <span data-toggle="modal" onClick={() => { this.setState({ showIsoCertificate: true }) }} data-target="#exampleModalsecond">
                                    <img className=" acccard card-img-top pt-2" style={{ height: "6vw", width: "13rem" }} src="https://images.vijayadiagnostic.com/website/banner/Banner/bureauveritasimg.png" alt="Card image cap" />
                                    <p className="card-text text-center">ISO 9001:2015</p>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                            <div className="card border-0 text-center" style={{ width: "15rem" }}>
                                <span onClick={() => { window && window.open('https://images.vijayadiagnostic.com/investor/accreditation/HMNR-MC-2657.pdf', '_blank') }}>
                                    <img className="acccard card-img-top" style={{ height: "7vw", width: "5rem" }} src="https://images.vijayadiagnostic.com/website/banner/Banner/qglobal-nabl-accreditation.png" alt="Card image cap" />
                                    <p className="card-text text-center">MC-2657</p>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 text-center">
                            <div className="card border-0 text-center" style={{ width: "15rem" }}>
                                <span onClick={() => { window && window.open('https://images.vijayadiagnostic.com/investor/accreditation/Certificate_30129_HMNR.pdf', '_blank') }}>
                                    <img className="acccard card-img-top" style={{ height: "7vw", width: "8rem" }} src="https://images.vijayadiagnostic.com/website/banner/Banner/nabh.jpg" alt="Card image cap" />
                                    <p className="card-text text-center">MIS-2021-0130</p>
                                </span>
                            </div>
                        </div>

                        <div className='col-xl-2 col-lg-2 col-md-3 col-sm-12 text-center'>
                            <div className='parent w-100'>
                                <div className='child'>
                                    <Link href={`/accreditation`}>
                                        <a className='btn view_more' style={{ backgroundColor: "#04b7af", color: "#FFFFFF" }}>View More &gt;</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {this.state.showIsoCertificate &&
                    <div className="modal fade" id="exampleModalsecond" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-content">
                                    <div className="modal-header p-10">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <img src="https://images.vijayadiagnostic.com/website/banner/ISO Certificate 2020-1.jpg" alt="ISO Certificate" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* <div className="container">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 mb-4'>
                            <h4 className='india_txt'>INDIA'S LARGEST HEALTHCARE PLATFORM</h4>
                        </div>
                    </div>
                    <div className='row count_txt'>
                        <div className='col-lg-3 col-md-3 col-sm-3'>
                            <h3><CountUp end={95} duration={5} /> +</h3>
                            <p>Diagnostic Centres and Counting</p>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <h3><CountUp end={200} duration={5} /> +</h3>
                            <p>Professionals</p>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <h3><CountUp end={15} duration={5} /></h3>
                            <p>Cities Across India</p>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-3'>
                            <h3><CountUp end={250} duration={5} /> +</h3>
                            <p>Corporate Clients and Counting</p>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                            <h3><CountUp end={40} duration={5} /> +</h3>
                            <p>Years of Experience</p>
                        </div>
                    </div>
                </div> */}
            </ErrorBoundary>
        )
    }
}
export default connect(withTranslation(['common'])(HealthcarePlatform));